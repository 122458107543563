import React, { useRef } from 'react'
import { BillingLetterPortPdfProps } from './types'
import { CfdiLetterPorte, Timbre } from '../../services/BillingService/types'

import { Row, Col } from 'reactstrap'

import imageReport from 'img/logo_ptx_invoice.png'
import 'styles/billing_pdf.scss'
import { useReactToPrint } from 'react-to-print'
import { BillingService } from 'modules/billing/services'
import QRCode from 'react-qr-code'
import { AButton } from 'shared/components/common'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { CFDI_USES } from './cfdiUses'
import { TAX_REGIMENS } from './taxRegimens'

// GENERAL COMPONENTES
const WrapperPdf: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => <div className="billing-pdf-body" {...props} />
const RowHeader: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="billing-rows-header" {...props} />
const BoldTextHeader: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => (
  <span className="billing-bold-header" {...props} />
)
const TextSmall: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="font-small font-black" {...props} />

const TableTh: React.FunctionComponent = ({ children }) => (
  <th className="secondary-text table-borde table-th">
    <div className="table-th-tr-content font-small">{children}</div>
  </th>
)
const TableTd: React.FunctionComponent = ({ children }) => (
  <td className="tableBorde">
    <div className="table-th-tr-content fs-10">{children}</div>
  </td>
)
const HeaderParagraph: React.FunctionComponent<React.HTMLProps<HTMLParagraphElement>> = props => (
  <p className="billing-header-paragraph" {...props} />
)

// PRINCIPAL COMPONENT
export const BillingLetterPortPfg = (props: BillingLetterPortPdfProps) => {
  const printComponentRef = useRef(null)
  const { listCfdi } = props
  const uuID = listCfdi[0].timbre.TimbreFiscalDigital.UUID
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: uuID
  })

  const getQrSrc = (timbre: Timbre) => {
    return BillingService.generateQrSat({
      timbreFiscalId: timbre.TimbreFiscalDigital.UUID,
      emisorRfc: timbre.EmisorRfc,
      receptorRfc: timbre.ReceptorRfc,
      total: timbre.Total,
      sello: timbre.Sello
    })
  }

  const getCadenaCFDI = function(timbre: Timbre) {
    const FechaTimbrado = timbre.TimbreFiscalDigital.FechaTimbrado
    const UUID = timbre.TimbreFiscalDigital.UUID
    const RfcProvCertif = timbre.TimbreFiscalDigital.RfcProvCertif
    const SelloCFD = timbre.TimbreFiscalDigital.SelloCFD
    const SelloSAT = timbre.TimbreFiscalDigital.SelloSAT
    const NoCertificadoSAT = timbre.TimbreFiscalDigital.NoCertificadoSAT
    const cadenaOrginal = `||${UUID}|${FechaTimbrado}|${RfcProvCertif}${SelloCFD}|${SelloSAT}|${NoCertificadoSAT}||`
    return cadenaOrginal
  }

  const formatDate = (date: Date) => {
    return moment(date).format('DD/MM/yyyy HH:mm:ss')
  }
  const formatDateSimple = (date: Date) => {
    return moment(date).format('DD/MM/yyyy')
  }

  const getFormaPagoText = (pm: string) => {
    switch (pm) {
      case '01':
        return 'Efectivo'
      case '02':
        return 'Cheque nominativo'
      case '03':
        return 'Transferencia electrónica de fondos'
      case '04':
        return 'Tarjeta de crédito'
      case '28':
        return 'Tarjeta de débito'
      case '99':
        return 'Por definir'
      default:
        return ''
    }
  }

  const getPaymentMethodText = (pm: string) => {
    switch (pm) {
      case 'PUE':
        return 'Pago en una sola Exhibición'
      case 'PPD':
        return 'Pago en Parcialidades o Diferido'
      default:
        return ''
    }
  }

  const getTaxRegimenDescription = (regimen: string) => {
    const taxRegimen = TAX_REGIMENS.find((t: any) => t.code === regimen)
    return taxRegimen ? taxRegimen.description : ''
  }

  const getUsoCFDIDescription = (uso: string) => {
    const usoCFDI = CFDI_USES.find((t: any) => t.code === uso)
    return usoCFDI ? usoCFDI.description : ''
  }

  const getTipoComprobante = (tipo: string) => {
    switch (tipo) {
      case 'I':
        return 'Ingreso'
      case 'E':
        return 'Egreso'
      default:
        return ''
    }
  }

  const formatCurrency = (value: string) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(parseFloat(value))
  }

  const handleGoBack = () => {
    window.location.href = '/solicitarfactura'
  }

  const handleDownloadXml = () => {
    // @ts-ignore
    for (let i = 0; i < listCfdi.length; i += 1) {
      const tituloDoc = listCfdi[i].timbre.TimbreFiscalDigital.UUID
      const rawXml = listCfdi[i].xml
      const blobXml = new Blob([atob(rawXml)], { type: 'text/xml' })
      saveAs(blobXml, `${tituloDoc}.xml`)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <div>
          <AButton variant="gray" onClick={handleGoBack} className="mb-0 fs-16">
            Regresar
          </AButton>
          &nbsp; &nbsp;
          <AButton variant="med" onClick={handlePrint}>
            Imprimir pdf
          </AButton>
          &nbsp; &nbsp;
          <AButton variant="med" onClick={handleDownloadXml}>
            Descargar xml
          </AButton>
        </div>
      </div>
      <div ref={printComponentRef}>
        {listCfdi.map((cfdi: CfdiLetterPorte) => (
          <WrapperPdf key={cfdi.id}>
            <div className="invoice-data">
              {/* info principal */}
              <div className="d-flex mb-0 mt-2 mb-3">
                <div className="d-flex" style={{ flex: '40%' }}>
                  <div className="m-auto text-center">
                    <img src={imageReport} />
                  </div>
                </div>
                <div className="ml-4" style={{ flex: '60%' }}>
                  <h5 className="billing-header-title">{cfdi.timbre.EmisorNombre}</h5>
                  <HeaderParagraph>RFC: {cfdi.timbre.EmisorRfc}</HeaderParagraph>
                  <HeaderParagraph>Tipo de comprobante: {getTipoComprobante(cfdi.timbre.TipoDeComprobante)}</HeaderParagraph>
                  <HeaderParagraph>Versión {cfdi.timbre.Version}</HeaderParagraph>
                  <HeaderParagraph>Lugar de Expedición: {cfdi.timbre.LugarExpedicion}</HeaderParagraph>
                  <HeaderParagraph>
                    Régimen Fiscal: {cfdi.timbre.EmisorRegimenFiscal} - {getTaxRegimenDescription(cfdi.timbre.EmisorRegimenFiscal)}
                  </HeaderParagraph>
                  <HeaderParagraph>Folio Fiscal: {cfdi.timbre.TimbreFiscalDigital.UUID}</HeaderParagraph>
                  <HeaderParagraph>No de Serie de Certificado del CSD: {cfdi.timbre.NoCertificado}</HeaderParagraph>
                </div>
              </div>

              {/* generales dela factura */}
              <div className="d-flex w-100">
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Forma de pago:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">
                    {cfdi.timbre.FormaPago} - {getFormaPagoText(cfdi.timbre.FormaPago)}
                  </div>
                </div>
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Serie:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">{cfdi.timbre.Serie}</div>
                </div>
              </div>

              <div className="d-flex w-100">
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Método de pago:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">
                    {cfdi.timbre.MetodoPago} - {getPaymentMethodText(cfdi.timbre.MetodoPago)}
                  </div>
                </div>
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Folio:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">{cfdi.timbre.Folio}</div>
                </div>
              </div>

              <div className="d-flex w-100">
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Moneda:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">{cfdi.timbre.Moneda} ‐ Peso Mexicano</div>
                </div>
                <div className="d-flex w-50">
                  <div className="d-flex w-25">
                    <BoldTextHeader>Fecha:</BoldTextHeader>
                  </div>
                  <div className="d-flex w-75">{formatDate(cfdi.timbre.Fecha)}</div>
                </div>
              </div>

              <div className="mt-2 mb-2">
                <RowHeader>Datos del cliente</RowHeader>
                <div className="d-flex w-100">
                  <div className="d-flex w-50">
                    <div className="d-flex w-25">
                      <BoldTextHeader>Cliente:</BoldTextHeader>
                    </div>
                    <div className="d-flex w-75"> {cfdi.timbre.ReceptorNombre} </div>
                  </div>
                  <div className="d-flex w-50">
                    <div className="d-flex w-25">
                      <BoldTextHeader>Uso de CFDI:</BoldTextHeader>
                    </div>
                    <div className="d-flex w-75">
                      {cfdi.timbre.ReceptorUsoCFDI} - {getUsoCFDIDescription(cfdi.timbre.ReceptorUsoCFDI)}
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="d-flex w-50">
                    <div className="d-flex w-25">
                      <BoldTextHeader>Rfc:</BoldTextHeader>
                    </div>
                    <div className="d-flex w-75"> {cfdi.timbre.ReceptorRfc} </div>
                  </div>
                  <div className="d-flex w-50">
                    <div className="d-flex w-25">
                      <BoldTextHeader>Régimen fiscal:</BoldTextHeader>
                    </div>
                    <div className="d-flex w-75">
                      {cfdi.timbre.RegimenFiscalReceptor} - {getTaxRegimenDescription(cfdi.timbre.RegimenFiscalReceptor)}
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="d-flex w-50">
                    <div className="d-flex w-25">
                      <BoldTextHeader>C.P:</BoldTextHeader>
                    </div>
                    <div className="d-flex w-75"> {cfdi.timbre.DomicilioFiscalReceptor} </div>
                  </div>
                </div>
              </div>

              {/* conceptos */}
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>Cant.</TableTh>
                    <TableTh>Servicio</TableTh>
                    <TableTh>Unidad</TableTh>
                    <TableTh>No. Identificación</TableTh>
                    <TableTh>Descripción</TableTh>
                    <TableTh>Valor Unitario</TableTh>
                    <TableTh>Descuento</TableTh>
                    <TableTh>Importe</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.Concepto.map(concept => (
                    <tr ng-if="cfdi.timbre.Concepto.length> 0" key={concept.ClaveProdServ}>
                      <TableTd>{concept.Cantidad}</TableTd>
                      <TableTd>{concept.ClaveProdServ}</TableTd>
                      <TableTd>{concept.ClaveUnidad}</TableTd>
                      <TableTd>{concept.NoIdentificacion}</TableTd>
                      <TableTd>{concept.Descripcion}</TableTd>
                      <TableTd>{formatCurrency(concept.ValorUnitario)}</TableTd>
                      <TableTd>{concept.Descuento === undefined ? formatCurrency('0') : formatCurrency(concept.Descuento)}</TableTd>
                      <TableTd>{formatCurrency(concept.Importe)}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Row>
                <Col sm={6}>
                  <p className="m-0">
                    <BoldTextHeader>Total con letra:</BoldTextHeader>
                  </p>
                  <p className="m-0">
                    <TextSmall>({cfdi.timbre.totalLetra} 00/100 MX)</TextSmall>
                  </p>
                </Col>
                <Col sm={6}>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <table>
                      <tr>
                        <td className="text-right">
                          <RowHeader>SUBTOTAL</RowHeader>
                        </td>
                        <td width={30} />
                        <td className="text-right">{formatCurrency(cfdi.timbre.SubTotal)}</td>
                      </tr>
                      <tr>
                        <td className="text-right">
                          <RowHeader>IVA</RowHeader>
                        </td>
                        <td width={30} />
                        <td className="text-right">{formatCurrency(cfdi.timbre.ImporteImpuesto)}</td>
                      </tr>
                      {cfdi.timbre.ImporteImpuestoRetenido && (
                        <tr>
                          <td className="text-right">
                            <RowHeader>IVA RET.</RowHeader>
                          </td>
                          <td width={30} />
                          <td className="text-right">{formatCurrency(cfdi.timbre.ImporteImpuestoRetenido)}</td>
                        </tr>
                      )}
                      <tr>
                        <td className="text-right">
                          <RowHeader>TOTAL</RowHeader>
                        </td>
                        <td width={30} />
                        <td className="text-right">{formatCurrency(cfdi.timbre.Total)}</td>
                      </tr>
                    </table>
                  </div>
                </Col>
              </Row>

              {/* pagare */}
              <div className="d-flex">
                <div className="d-flex flex-column" style={{ flex: '25%' }}>
                  <h6 className="m-0 bold mb-5px fs-12">INFORMACIÓN PARA PAGO</h6>
                  <h6 className="m-0 bold fs-12">
                    CUENTA BANORTE: &nbsp;
                    <span style={{ fontWeight: 'normal' }}>0332109381</span>
                  </h6>
                  <h6 className="m-0 bold fs-12">
                    CLABE: &nbsp;
                    <span style={{ fontWeight: 'normal' }}>072 743 00332109381 3</span>
                  </h6>
                  <h6 className="m-0 bold fs-12">
                    REFERENCIA: &nbsp;
                    <span style={{ fontWeight: 'normal' }}>{cfdi.timbre.Folio}</span>
                  </h6>
                </div>
                <div className="d-flex flex-column pl-10" style={{ flex: '75%' }}>
                  <h6 className="bold mb-5px">
                    <span className="fw-normal fs-12">PAGARE NO.&nbsp;&nbsp;</span>
                    <strong className="fs-12">
                      {cfdi.timbre.Serie} {cfdi.timbre.Folio}
                    </strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="fw-normal fs-12">A {formatDateSimple(cfdi.timbre.Fecha)}</span>
                  </h6>
                  <p className="m-0 black-f" style={{ textAlign: 'justify' }}>
                    POR EL PRESENTE PAGARÉ RECONOZCO DEBER Y ME OBLIGO A PAGAR INCONDICIONALMENTE EN ESTA CIUDAD O EN CUALQUIER OTRA QUE SE
                    ME REQUIERA EL PAGO A AUTOTRANSPORTES Y CARGA PTX S.A.P.I. de C.V. A MÁS TARDAR EL DÍA ___ DE ________ DEL _____ LA
                    CANTIDAD DE <strong>{formatCurrency(cfdi.timbre.Total)} </strong>
                    <span className="f-italic">{cfdi.timbre.totalLetra} 00/100 MXN </span>
                    VALOR RECIBIDO EN SERVICIOS A MI ENTERA SATISFACCIÓN. SI ESTE PAGARÉ NO SE PAGA A SU FECHA DE VENCIMIENTO CAUSARA
                    INTERESES MORATORIOS DEL 5% MENSUAL MÁS IVA, PAGADERO EN ESTA CIUDAD JUNTAMENTE CON EL PRINCIPAL.
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex w-100 mt-5px">
              <p className="m-auto text-center bold black-f">Este documento es una representación impresa de un CFDI</p>
            </div>
            <div>
              <b>SELLO DIGITAL DEL CFDI</b>
              <p className="AjusteSello mb-0" style={{ fontSize: 8, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloCFD}
              </p>

              <b>SELLO DEL SAT</b>
              <p className="AjusteSello mb-0" style={{ fontSize: 8, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloSAT}
              </p>

              <div className="d-flex mt-5px">
                <div className="w-20">
                  <QRCode value={getQrSrc(cfdi.timbre)} size={120} />
                </div>
                <div style={{ width: '80%', paddingLeft: 20 }}>
                  <b>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACIÓN DIGITAL</b>
                  <p className="AjusteSello mb-0" style={{ fontSize: 8, color: '#000' }}>
                    {getCadenaCFDI(cfdi.timbre)}
                  </p>

                  <div>
                    <div className="d-flex">
                      <b>No de Serie del certificado del SAT:</b>
                      <div className="ml-2">{cfdi.timbre.TimbreFiscalDigital.NoCertificadoSAT}</div>
                    </div>

                    <div className="d-flex">
                      <b>Fecha y hora de certificación:</b>
                      <div className="ml-2">{formatDate(cfdi.timbre.TimbreFiscalDigital.FechaTimbrado)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WrapperPdf>
        ))}
      </div>
    </>
  )
}
